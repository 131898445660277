@import url("https://fonts.cdnfonts.com/css/agency-fb");

* {
  margin: 0;
  padding: 0;
  font-family: "Agency FB", sans-serif;
}
/* Estilo del placeholder en todos los navegadores modernos */
::placeholder {
  color: white; /* Cambia el color a tu preferencia */
}

/* Estilo específico para navegadores WebKit (como Chrome y Safari) */
:-webkit-input-placeholder {
  color: white;
}

/* Estilo específico para navegadores Firefox */
:-moz-placeholder {
  color: white;
}

/* Estilo específico para navegadores Internet Explorer 10 y 11 */
::-ms-input-placeholder {
  color: white;
}

a {
  color: white;
  text-decoration: none;
}
